import React from 'react'
import Image from '../../image'

const HeroInnerpage = ({ oHero, mAlt }) => {

   return (
      <div className={`hero hero--innerpage container${ mAlt ? ` hero--innerpage-alt` : ''}`}>
         <div className="hero__title">
            <h1>{oHero.left.title}</h1>
         </div>

         <div className="hero__image hero__image-medium">
            <div className="inner-box">
               <Image imageID={oHero.left.image_one} mSize={`medium`} />
            </div>
         </div>

         <div className="hero__image hero__image-small">
            <div className="inner-box">
               <Image imageID={oHero.left.image_two} mSize={`medium`} />
            </div>
         </div>

         <div className="hero__image hero__image-vertical">
            <div className="inner-box">
               <Image imageID={oHero.right.image} mSize={`medium`} />
            </div>
         </div>
      </div>
   )
}

export default HeroInnerpage