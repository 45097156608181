import React from 'react'
import Parse from 'html-react-parser'

const BlockHighlight = ({ oBlockHighlight }) => {
   
   return (
      <aside className="block-highlight">
         <div>
            <h3 className="h4 lora">{oBlockHighlight.title && Parse(oBlockHighlight.title)}</h3>
            {oBlockHighlight.text && Parse(oBlockHighlight.text)}
         </div>
      </aside>
   )
}

export default BlockHighlight