import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

// Components
import BlockHours from '../../components/elements/02-blocks/BlockHours'
import HeroInnerpage from "../../components/elements/10-hero/HeroInnerpage"
import BlockService from '../../components/elements/02-blocks/BlockService'
import BlockHighlight from '../../components/elements/02-blocks/BlockHighlight'

const Page = ({ data }) => {

   let parsedData = data.wordpressPage.acf
   
   let heroData = parsedData.traiteur_hero_innerpage
   let oHero = {}

   if(heroData) {
      oHero = {
         left: {
            title: heroData.traiteur_hero_innerpage_left.traiteur_hero_innerpage_left_title,
            image_one: heroData.traiteur_hero_innerpage_left.traiteur_hero_innerpage_left_image_one,
            image_two: heroData.traiteur_hero_innerpage_left.traiteur_hero_innerpage_left_image_two
         },
         right: {
            image: heroData.traiteur_hero_innerpage_right.traiteur_hero_innerpage_right_image
         }
      }
   }
   
  

   let blockData = parsedData.traiteur_double_two_columns_service
   
   let oBlockServiceFirst = {
      title: blockData.traiteur_double_two_columns_service_column_one.traiteur_double_two_columns_service_column_one_title,
      text: blockData.traiteur_double_two_columns_service_column_one.traiteur_double_two_columns_service_column_one_text,
      image: blockData.traiteur_double_two_columns_service_column_one.traiteur_double_two_columns_service_column_one_image,
   }

   if(blockData.traiteur_double_two_columns_service_column_one.traiteur_double_two_columns_service_column_one_button) {
      oBlockServiceFirst.button = {
         title: blockData.traiteur_double_two_columns_service_column_one.traiteur_double_two_columns_service_column_one_button.title,
         url: blockData.traiteur_double_two_columns_service_column_one.traiteur_double_two_columns_service_column_one_button.url
      }
   }

   let oBlockServiceSecond = {
      title: blockData.traiteur_double_two_columns_service_column_two.traiteur_double_two_columns_service_column_two_title,
      text: blockData.traiteur_double_two_columns_service_column_two.traiteur_double_two_columns_service_column_two_text,
      image: blockData.traiteur_double_two_columns_service_column_two.traiteur_double_two_columns_service_column_two_image,
   }

   if(blockData.traiteur_double_two_columns_service_column_two.traiteur_double_two_columns_service_column_two_button) {
      oBlockServiceSecond.button = {
         title: blockData.traiteur_double_two_columns_service_column_two.traiteur_double_two_columns_service_column_two_button.title,
         url: blockData.traiteur_double_two_columns_service_column_two.traiteur_double_two_columns_service_column_two_button.url
      }
   }

   let oBlockServiceThree = {
      title: blockData.traiteur_double_two_columns_service_column_three.traiteur_double_two_columns_service_column_three_title,
      text: blockData.traiteur_double_two_columns_service_column_three.traiteur_double_two_columns_service_column_three_text,
      image: blockData.traiteur_double_two_columns_service_column_three.traiteur_double_two_columns_service_column_three_image,
   }

   if(blockData.traiteur_double_two_columns_service_column_three.traiteur_double_two_columns_service_column_three_button) {
      oBlockServiceThree.button = {
         title: blockData.traiteur_double_two_columns_service_column_three.traiteur_double_two_columns_service_column_three_button.title,
         url: blockData.traiteur_double_two_columns_service_column_three.traiteur_double_two_columns_service_column_three_button.url
      }
   }

   let oBlockServiceFour = {
      title: blockData.traiteur_double_two_columns_service_column_four.traiteur_double_two_columns_service_column_four_title,
      text: blockData.traiteur_double_two_columns_service_column_four.traiteur_double_two_columns_service_column_four_text,
      image: blockData.traiteur_double_two_columns_service_column_four.traiteur_double_two_columns_service_column_four_image,
   }

   if(blockData.traiteur_double_two_columns_service_column_four.traiteur_double_two_columns_service_column_four_button) {
      oBlockServiceFour.button = {
         title: blockData.traiteur_double_two_columns_service_column_four.traiteur_double_two_columns_service_column_four_button.title,
         url: blockData.traiteur_double_two_columns_service_column_four.traiteur_double_two_columns_service_column_four_button.url
      }
   }

   let oBlockServiceFive = {
      title: blockData.traiteur_double_two_columns_service_column_five.traiteur_double_two_columns_service_column_five_title,
      text: blockData.traiteur_double_two_columns_service_column_five.traiteur_double_two_columns_service_column_five_text,
      image: blockData.traiteur_double_two_columns_service_column_five.traiteur_double_two_columns_service_column_five_image,
   }

   if(blockData.traiteur_double_two_columns_service_column_five.traiteur_double_two_columns_service_column_five_button) {
      oBlockServiceFive.button = {
         title: blockData.traiteur_double_two_columns_service_column_five.traiteur_double_two_columns_service_column_five_button.title,
         url: blockData.traiteur_double_two_columns_service_column_five.traiteur_double_two_columns_service_column_five_button.url
      }
   }

   let BlockHighlightData = parsedData.traiteur_cta
   let oBlockHighlight = {
      title: BlockHighlightData.traiteur_cta_title,
      text: BlockHighlightData.traiteur_cta_text
   }

   return (
      <Layout>
         <SEO WPPageID={data.wordpressPage.wordpress_id} />
         
         <div className="site page page--traiteur">
            <BlockHours />
            
            <main id="main">
            { oHero && <HeroInnerpage oHero={oHero} mAlt={true} /> }

               <div className="container">
                  <h2 className="background-line"><span>Nos services</span></h2>

                  <div className="page--traiteur__services">
                     <BlockService oBlockService={oBlockServiceFirst} />
                     <BlockService oBlockService={oBlockServiceSecond} />
                     <BlockService oBlockService={oBlockServiceThree} />
                     <BlockService oBlockService={oBlockServiceFour} />
                     <BlockService oBlockService={oBlockServiceFive} />
                  </div>

                  <BlockHighlight oBlockHighlight={oBlockHighlight} />
               </div>
               
            </main>
         </div>
      </Layout>
   )
}

export default Page

export const query = graphql`
   query($id: Int!) {
      wordpressPage(wordpress_id: { eq: $id }) {
         wordpress_id
         content
         title
         acf {
            traiteur_cta {
               traiteur_cta_text
               traiteur_cta_title
            }
            traiteur_hero_innerpage {
               traiteur_hero_innerpage_left {
                  traiteur_hero_innerpage_left_image_one
                  traiteur_hero_innerpage_left_image_two
                  traiteur_hero_innerpage_left_title
               }
               traiteur_hero_innerpage_right {
                  traiteur_hero_innerpage_right_image
               }
            }

            traiteur_double_two_columns_service {
               traiteur_double_two_columns_service_title
               traiteur_double_two_columns_service_column_two {
                  traiteur_double_two_columns_service_column_two_title
                  traiteur_double_two_columns_service_column_two_text
                  traiteur_double_two_columns_service_column_two_image
                  traiteur_double_two_columns_service_column_two_button {
                     title
                     url
                  }
               }
               traiteur_double_two_columns_service_column_three {
                  traiteur_double_two_columns_service_column_three_title
                  traiteur_double_two_columns_service_column_three_text
                  traiteur_double_two_columns_service_column_three_image
                  traiteur_double_two_columns_service_column_three_button {
                     title
                     url
                  }
               }
               traiteur_double_two_columns_service_column_one {
                  traiteur_double_two_columns_service_column_one_title
                  traiteur_double_two_columns_service_column_one_text
                  traiteur_double_two_columns_service_column_one_image
                  traiteur_double_two_columns_service_column_one_button {
                     title
                     url
                  }
               }
               traiteur_double_two_columns_service_column_four {
                  traiteur_double_two_columns_service_column_four_title
                  traiteur_double_two_columns_service_column_four_text
                  traiteur_double_two_columns_service_column_four_image
                  traiteur_double_two_columns_service_column_four_button {
                     url
                     title
                  }
               }
               traiteur_double_two_columns_service_column_five {
                  traiteur_double_two_columns_service_column_five_image
                  traiteur_double_two_columns_service_column_five_text
                  traiteur_double_two_columns_service_column_five_button {
                     url
                     title
                     target
                  }
                  traiteur_double_two_columns_service_column_five_title
               }
            }
         }
      }
   }
`
